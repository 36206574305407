import React from "react";
import { useQuery, gql } from "@apollo/client";
import { PageBuilderFragment as pageBuilder } from "../query_fragments";
import queryString from "query-string";

import SEO from "@components/SEO";
import Hero from "@components/hero";
import PageBuilder from "@components/pageBuilder";
import ResourcesListing from "@components/resources-listing";

const Preview = ({ location }) => {
  const { post, nonce, preview } = queryString.parse(location?.search);

  // Id needs to be an int for preview query.
  const id = parseInt(post, 10);

  const { loading, error, data } = useQuery(PREVIEW_QUERY, {
    variables: {
      id,
      nonce,
      preview,
    },
  });

  if (loading) return null;
  if (error) return `${error}`;

  const { title, seo, isFrontPage, template } = data?.page;
  const heroProps = template?.Hero;
  const components = template?.pageBuilder?.pageBuilder;
  const resourcesProps = template?.templateResources;

  return (
    <>
      {/* {seo && <SEO {...seo} />} */}
      {heroProps && <Hero {...heroProps} isFrontPage={isFrontPage} />}
      <PageBuilder components={components} />
      {resourcesProps && (
        <div className="splodges">
          <ResourcesListing {...resourcesProps} />
        </div>
      )}
    </>
  );
};

// @TODO: Preview fragment
const PREVIEW_QUERY = gql`
  query getPreview($id: ID!) {
    page(id: $id, asPreview: true, idType: DATABASE_ID) {
      id
      title
      isFrontPage
      template {
        ... on DefaultTemplate {
          Hero {
            fieldGroupName
            heading
            content
            callToActions {
              link: callToAction {
                url
                label: title
                target
              }
            }
            image {
              previewFile: sourceUrl(size: HERO)
            }
          }
          pageBuilder {
            pageBuilder {
              ... on DefaultTemplate_Pagebuilder_PageBuilder_Cta {
                fieldGroupName
                heading
                content
                isFlipped: isflipped
                callToActions {
                  ... on DefaultTemplate_Pagebuilder_PageBuilder_Cta_CallToActions_Link {
                    fieldGroupName
                    link {
                      target
                      label: title
                      url
                    }
                  }
                }
                image {
                  altText
                  previewFile: sourceUrl(size: CTA)
                }
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_SteppedCardGroup {
                content
                fieldGroupName
                heading
                cards {
                  heading
                  content
                  callToAction {
                    ... on DefaultTemplate_Pagebuilder_PageBuilder_SteppedCardGroup_cards_CallToAction_Link {
                      link {
                        target
                        label: title
                        url
                      }
                    }
                  }
                }
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_CardGroup {
                fieldGroupName
                heading
                content
                numberOfCardsPerRow
                cards {
                  fieldGroupName
                  heading
                  content
                  callToAction {
                    ... on DefaultTemplate_Pagebuilder_PageBuilder_CardGroup_cards_CallToAction_Link {
                      fieldGroupName
                      link {
                        target
                        label: title
                        url
                      }
                    }
                  }
                }
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_VideoPanel {
                fieldGroupName
                content
                heading
                transcript
                video
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_Benefits {
                fieldGroupName
                heading
                content
                keypoints {
                  content
                }
                image {
                  altText
                  previewFile: sourceUrl(size: BENEFITS)
                }
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_ContentBlock {
                fieldGroupName
                content
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_BusTool {
                fieldGroupName
                noticeBoxCopy
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_AccordionGroup {
                fieldGroupName
                accordions {
                  heading
                  content
                  fieldGroupName
                  accordions {
                    ... on FAQ {
                      id
                      title
                      content
                    }
                  }
                }
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_Intro {
                fieldGroupName
                heading
                content
                isctaabovecontent: isctaabovecontent
                callToActions {
                  ... on DefaultTemplate_Pagebuilder_PageBuilder_Intro_CallToActions_Link {
                    fieldGroupName
                    link {
                      target
                      label: title
                      url
                    }
                  }
                }
              }
              ... on DefaultTemplate_Pagebuilder_PageBuilder_ImageGroup {
                fieldGroupName
                heading
                content
                callToActions {
                  callToAction {
                    target
                    label: title
                    url
                  }
                }
                images {
                  image {
                    altText
                    previewFile: sourceUrl
                  }
                }
              }
            }
          }
        }

        ... on Template_Resources {
          Hero {
            heading
            content
            additionalContentContent
            additionalContentHeading
            additionalContentResources {
              ... on Template_Resources_Hero_AdditionalContentResources_Link {
                link {
                  label: title
                  target
                  url
                }
              }
              ... on Template_Resources_Hero_AdditionalContentResources_File {
                file {
                  fileSize
                  title
                  mediaType
                  mediaItemUrl
                }
              }
            }
            callToActions {
              callToAction {
                target
                label: title
                url
              }
            }
          }
          templateName
          templateResources {
            heading: resourcesHeading
            content: resourcesContent
            resources {
              resourceList {
                ... on Template_Resources_Templateresources_resources_ResourceList_Link {
                  link {
                    label: title
                    target
                    url
                  }
                  fieldGroupName
                }
                ... on Template_Resources_Templateresources_resources_ResourceList_File {
                  fieldGroupName
                  file {
                    fileSize
                    title
                    mediaType
                    mediaItemUrl
                  }
                }
              }
              heading
              fieldGroupName
            }
            fieldGroupName
          }
        }
      }
    }

    footerSettings {
      footerSettings {
        partners {
          partnerLink {
            target
            title
            url
          }
          partnerLogo {
            previewFile: sourceUrl
          }
        }
        socialTitle
        socialProfiles {
          socialProfile {
            target
            label: title
            url
          }
        }
      }
    }

    generalSettings {
      url
    }
  }
`;

export default Preview;
