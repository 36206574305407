import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { rem } from "polished";

import { NarrowContentInner } from "@components/layouts";

export const Resources = styled(NarrowContentInner)`
  padding-top: ${rem("30px")};
  padding-bottom: ${rem("55px")};

  ${breakpoint("desktop-sm")`
    padding-top: ${rem("55px")};
    padding-bottom: ${rem("115px")};
  `};

  > * {
    max-width: 85ch;
  }
`;

export const ResourcesListingHeading = styled.h2`
  margin-bottom: ${rem("40px")};
  
  ${breakpoint("desktop-sm")`
    margin-bottom: ${rem("45px")};
  `};
`;

export const ResourcesListingContent = styled.div`
  margin-bottom: ${rem("40px")};

  ${breakpoint("desktop-sm")`
    margin-bottom: ${rem("45px")};
  `};
`;

export const ResourcesListingSubHeading = styled.h3`
  margin-bottom: ${rem("30px")};
`;
