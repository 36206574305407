import React from "react";
import Parser from "html-react-parser";
import { v4 as uuidv4 } from 'uuid';
import { prettySize }from '@helpers';

import Panel from "@components/panel";

import {
  Resources,
  ResourcesListingHeading,
  ResourcesListingContent,
  ResourcesListingSubHeading,
} from "./style";

import {
  ResourcesListing,
  ResourcesListingItem,
  ExternalLink,
  DownloadLink,
  DownloadLinkFileInfo
} from "@components/link/linkStyles";

const ResourcesComponent = ({ heading, content, resources }) => {
  return (
    <Panel isDirectChild variant="white" backgroundOpacity=".1">
      <Resources>
        {heading && (
          <ResourcesListingHeading className="heading-lg">
            {heading}
          </ResourcesListingHeading>
        )}
        {content && (
          <ResourcesListingContent>{Parser(content)}</ResourcesListingContent>
        )}
        {resources && resources.length > 0 && (
          <>
            {resources.map(({ heading, resourceList }, index) => {
              return (
                <>
                  {heading && (
                    <ResourcesListingSubHeading className="heading-sm">
                      {heading}
                    </ResourcesListingSubHeading>
                  )}
                  {resourceList && (
                    <ResourcesListing>
                      {resourceList.map(({ link, file }, index) => {
                        let fileInfo = file?.localFile ? `(${file.localFile.ext.substring(1).toUpperCase()} ${file.localFile.prettySize})` : null;

                        // We are in preview mode, ext and prettySize not available
                        if(file?.mediaItemUrl && fileInfo === null) {
                          fileInfo = `(${file?.mediaItemUrl?.split('.').pop().toUpperCase()} ${prettySize(file?.fileSize) || ''})`;
                        }


                        return (
                          <>
                            {(link || file) && (
                              <ResourcesListingItem key={uuidv4()}>
                                {link && <ExternalLink {...link} />}
                                
                                {file && (
                                  <DownloadLink
                                    href={file.mediaItemUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                  >
                                    <span>{file.title}</span>
                                    {fileInfo && <DownloadLinkFileInfo> {fileInfo}</DownloadLinkFileInfo>}
                                  </DownloadLink>
                                )}
                              </ResourcesListingItem>
                            )}
                          </>
                        );
                      })}
                    </ResourcesListing>
                  )}
                </>
              );
            })}
          </>
        )}
      </Resources>
    </Panel>
  );
};

export default ResourcesComponent;
